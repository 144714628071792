import { HOSPITAL_DEVICES_LIST } from 'store/redux/actions/action.constants';

const initial_state = {
    hospitalDevicesListing: null
};

export default function (state = initial_state, action) {
    switch (action.type) {
        case HOSPITAL_DEVICES_LIST:
            return {
                ...state,
                hospitalDevicesListing: action.payload
            };
        default:
            return state;
    }
}
