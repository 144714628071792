// assets
import { IconTypography, IconShadow, IconWindmill } from '@tabler/icons';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MedicationIcon from '@mui/icons-material/Medication';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// constant
const icons = {
  IconTypography,
  PersonOutlineIcon,
  IconShadow,
  IconWindmill,
  MedicationIcon,
  BookOnlineIcon
};
// const isAdmin = useSelector((state) => state.auth.user.role) === 'admin';
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const authorUtilities = () => {
  const { t } = useTranslation();
  return {
    id: 'utilities',
    type: 'group',
    children: [
      {
        id: 'question-disease',
        title: t('Clinical_Pathways'),
        type: 'item',
        url: '/question-list',
        icon: icons.MedicationIcon,
        breadcrumbs: false
      }
    ]
  };
};
export default authorUtilities;
