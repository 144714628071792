import React, { useEffect, useState } from 'react';
import '../../../assets/scss/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Modal } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useLocation, useParams, useRoutes, useNavigate } from 'react-router';
import { formatedDate, formatedTime } from 'views/utilities/helper';
import { useDispatch, useSelector } from 'react-redux';
import DoctorList from '../Doctors/Tabs/Listing/ApointmentList';
import UserApointmentList from './_part/UserApointmentList';
import { getUserData } from 'store/redux/actions/user';
import UserClicicalPath from './_part/UserClinicalPath';
import { downloadPDF } from 'utils/Utils';
import { useTranslation } from 'react-i18next';
import loader from '../../../assets/images/loader.gif';

// import ReactPDF from '@react-pdf/renderer';
import { Button } from 'react-bootstrap';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { Constants } from 'constants/Constants';
import axios from 'axios';
import request from 'utils/request';
import makeToast from 'utils/Toaster';
import { LocalStorageKeys } from 'constants/LocalStorageKeys';

function UserDetail() {
  // const { state } = useLocation();
  const { t } = useTranslation();
  const { user_data: state } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [upid, setUpid] = useState('');
  const [downloading, setDownloading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!state) {
      dispatch(getUserData(JSON.parse(localStorage.getItem('userData'))));
    }
  }, []);

  const handleSubmit = async () => {
    setDownloading(true);
    try {
      //if (upid === state?.upic) {
      console.log('state ---------> ', state);
      // return;
      await downloadPDF(state);
      setShow(false);
      // makeToast(t('success'), 'Downloaded');
      // } else {
      //makeToast(t('error'), t('Invalid_UPIC'));
      // }
    } catch (error) {
      console.log('ERROR--> ', error);
      makeToast(t('error'), 'Failed');
    }
    setDownloading(false);
  };

  const handleUpicSubmit = async () => {
    const userId = state._id;
    const payload = {
      id: userId,
      upic: upid
    };
    try {
      const res = await request.post(Constants.updateUser_api_url, payload);
      const { status, data } = res.data;
      if (!status) throw res.data;
      if (status) {
        makeToast(t('success'), t('UPIC_Add_Success_msg'));
        setUpid('');
        setShow(false);
        dispatch(getUserData(data?.data));
      }
    } catch (e) {
      makeToast(t('error'), e);
    }
  };

  return (
    <div className="user-Details">
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('error_toastify_enter_UPIC')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-5 pt-3">
          {!state?.upic && (
            <div style={{ fontWeight: 'bold' }} className="pb-3">
              {t('Please_assign_UPIC_to_this_patient')}
            </div>
          )}
          <Form.Control type="text" placeholder={t('Enter_UPIC')} value={upid} onChange={(e) => setUpid(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          {state?.upic ? (
            <Button variant="primary" onClick={() => handleSubmit()}>
              {t('Submit')}
            </Button>
          ) : (
            <Button variant="primary" onClick={() => handleUpicSubmit()}>
              {t('Submit')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Typography variant="h4" sx={{ color: '#034562', marginBottom: '15px' }}>
        {t('User_Detail')}
      </Typography>

      <div className="tabs">
        <Tabs
          defaultActiveKey="Doctor-Detail"
          onSelect={(e) => {
            if (e === 'clinical-Listing') {
              // if (!state?.pdfUrl || state?.pdfUrl === '') makeToast('error', 'NO PDF URL');
              //setShow(true);
              console.log('DOWNLOAD CLICKED');
              handleSubmit();
            }
          }}
          id="uncontrolled-tab-example"
          className="mb-3 border-0"
        >
          <Tab eventKey="Doctor-Detail" title={t('User_Detail')}>
            <div className="detail">
              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="m-0">{t('Name')}</h5>
                    <p>{t('Removed_by_system')}</p>
                  </div>
                  <hr />
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="m-0">{t('DOB')}</h5>
                    <p>{t('Removed_by_system')}</p>
                  </div>
                  <hr />
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="m-0">{t('Phone')}</h5>
                    <p>{t('Removed_by_system')}</p>
                  </div>
                  <hr />
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </Tab>
          {/* <Tab eventKey="Doctor-Listing" title="User Appointment">
            <UserApointmentList id={state?._id} />
          </Tab> */}
          {/* <Button>Hello</Button> */}

          <Tab eventKey="clinical-Listing" title={t('Download_PDF')} disabled={downloading}>
            {downloading && (
              <div
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh'
                }}
              >
                <img className="loader_div" src={loader} alt="loading..." style={{ height: '5vh', width: '5vh', marginBottom: '25%' }} />
              </div>
            )}
            {/* Yo */}
            {/* <UserClicicalPath data={state} /> */}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default UserDetail;
