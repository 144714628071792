import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, CircularProgress, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { displayMultiLangLabelIfPresent } from 'utils/multiLangUtils';
import question from 'store/redux/reducers/question';
import { updateDisease } from 'store/redux/actions/disease';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import makeToast from 'utils/Toaster';
import { useSelector, useDispatch } from 'react-redux';
import { GET_ALL_QUESTION_DISEASEID, SELECT_DISEASE } from 'store/redux/actions/action.constants';
import { getQuestionByDisease } from 'store/redux/actions/questions';
import { Constants } from 'constants/Constants';

const CombinationModal = ({ setShow, isUpdate, selectedCombination, isForEcds, diseaseListing, fetchDiseases }) => {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.question);
  const selectedDisease = useSelector((state) => state.disease.selectedDisease);
  const [version, setVersion] = useState(isUpdate ? selectedCombination.version : 'a');
  let { state } = useLocation();
  const prevCombinations = isForEcds ? selectedDisease?.ecdsCombination : selectedDisease?.combination;
  const langInUse = localStorage.getItem('i18nextLng');
  const [overAllP, setOverAllP] = useState(isUpdate ? selectedCombination.overAllCode : 'P1');
  const [title, setTitle] = useState(isUpdate ? selectedCombination.title : '');
  const [saving, setSaving] = useState(false);
  const [addedCombinationQuestions, setAddedCombinationQuestions] = useState(isUpdate ? selectedCombination.combination : []);

  if (isForEcds)
    state = {
      diseaseName: 'ECDS_UK',
      version,
      diseaseId: Constants.ecdsPathwayId
    };

  useEffect(() => {
    if (state && state.diseaseId && !isForEcds)
      getQuestionByDisease({
        diseaseID: state?.diseaseId,
        version: state?.version
      }).then((res) => {
        const payload = res.data.filter((e) => !e.isDeleted);
        dispatch({
          type: GET_ALL_QUESTION_DISEASEID,
          payload
        });
      });
  }, []);

  useEffect(() => {
    if (state && state.diseaseId && isForEcds && version !== 'a') {
      if (!isUpdate) setAddedCombinationQuestions([]);
      getQuestionByDisease({
        diseaseID: state?.diseaseId,
        version: state?.version
      }).then((res) => {
        const payload = res.data.filter((e) => !e.isDeleted);
        dispatch({
          type: GET_ALL_QUESTION_DISEASEID,
          payload
        });
      });
    }
  }, [version]);

  useEffect(() => {
    if (isUpdate && isForEcds) {
      const combinationDisease = diseaseListing.find((d) => d._id === selectedCombination.diseaseId);
      dispatch({
        type: SELECT_DISEASE,
        payload: combinationDisease
      });
    }
  }, []);

  const handleAddDiv = () => {
    setAddedCombinationQuestions((prev) => [...prev, {}]);
  };
  const getQuestions = () => {
    const filteredQuestions = questions.filter((question) => question?.questionType != 'input');
    // const alreadySelectedQuestions = addedCombinationQuestions.map((combinationQuestion) => combinationQuestion?.question?._id);
    const alreadySelectedQuestions = [];
    const finalArray = filteredQuestions.filter((question) => !alreadySelectedQuestions.includes(question._id));
    return finalArray;
  };
  const checkDisabledStatus = () => {
    if (isForEcds) {
      if (!title || addedCombinationQuestions.length < 1 || version === 'a') return true;
    } else if (addedCombinationQuestions.length < 2 || !title) return true;

    return addedCombinationQuestions.some((combinationQuestion) => !combinationQuestion.question || !combinationQuestion.option);
  };

  const handleSubmit = async () => {
    setSaving(true);
    const newCombination = { title, combination: addedCombinationQuestions, overAllCode: overAllP, version: state?.version };
    let dataToSend;
    if (isUpdate) {
      dataToSend = prevCombinations.map((combination) => (combination._id === selectedCombination._id ? newCombination : combination));
    } else {
      dataToSend = [...prevCombinations, newCombination];
    }
    console.log('DATA TO SEND => ', dataToSend);
    const KEY = isForEcds ? 'ecdsCombination' : 'combination';
    const response = await updateDisease({ id: selectedDisease._id, [KEY]: dataToSend });
    if (response && response?.status) {
      dispatch({
        type: SELECT_DISEASE,
        payload: response.data
      });
      if (isForEcds) await fetchDiseases();
      makeToast('success', 'Combination added successfully!');
      setShow(false);
    } else {
      makeToast('error', response?.message || 'Error');
      setSaving(false);
    }
  };
  
  return (
    <Modal
      className="question-modal"
      scrollable
      show
      size="lg"
      onHide={() => {
        setAddedCombinationQuestions([]);
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Combination</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          {isForEcds &&
            (diseaseListing.length > 0 ? (
              <div className="questionDropdown">
                <div className="row">
                  <div className="col-md-8">
                    <Autocomplete
                      style={{ borderRadius: 12, backgroundColor: '#fafafa' }}
                      options={diseaseListing}
                      getOptionLabel={(option) =>
                        displayMultiLangLabelIfPresent(option.diseaseName, langInUse, option.multiLanguageDiseaseName)
                      }
                      value={selectedDisease}
                      onChange={(e, newValue) =>
                        dispatch({
                          type: SELECT_DISEASE,
                          payload: newValue
                        })
                      }
                      hiddenLabel="true"
                      renderInput={(params) => <TextField {...params} placeholder="Select Pathway" />}
                      disabled={isUpdate}
                    />
                  </div>
                  <div className="col-md-3">
                    <FormControl fullWidth disabled={isUpdate}>
                      <InputLabel id="select-version">Version</InputLabel>
                      <Select
                        labelId="select-version"
                        id="select-version"
                        label="Version"
                        value={version}
                        onChange={(e) => {
                          if (e.target.value !== 'a') setVersion(e.target.value);
                        }}
                      >
                        <MenuItem defaultChecked value="a">
                          <em>Select Version</em>
                        </MenuItem>
                        {Constants.versions.map((data) => (
                          <MenuItem value={data?.value}>{data?.option}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <CircularProgress size={30} />
              </div>
            ))}
          {addedCombinationQuestions.length > 0 && (
            <>
              <TextField label="Title" required fullWidth value={title} onChange={(e) => setTitle(e.target.value)} className="mb-4" />
              {addedCombinationQuestions.map((combinationQuestion, index) => (
                <div className="questionDropdown" key={Math.random()}>
                  <div key={index} className="row">
                    <div className="col-md-8">
                      <Autocomplete
                        value={combinationQuestion?.question}
                        id="combo-box-demo"
                        options={getQuestions()}
                        getOptionLabel={(option) =>
                          displayMultiLangLabelIfPresent(option.question, langInUse, option.multiLanguageQuestion)
                        }
                        // sx={{ width: 300 }}
                        onChange={(event, newValue) => {
                          const newArray = [...addedCombinationQuestions];
                          newArray[index].question = newValue;
                          setAddedCombinationQuestions(newArray);
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Question" />}
                      />
                    </div>
                    <div className="col-md-3">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Option</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={combinationQuestion?.option}
                          label="Option"
                          onChange={(e) => {
                            const newArray = [...addedCombinationQuestions];
                            newArray[index].option = e.target.value;
                            setAddedCombinationQuestions(newArray);
                          }}
                        >
                          {combinationQuestion?.question?.options?.map((opt, i) => {
                            return (
                              <MenuItem value={opt._id} key={i}>
                                {displayMultiLangLabelIfPresent(opt?.option, langInUse, opt?.multiLanguageOption)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-1 d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => {
                          const newArray = [...addedCombinationQuestions];
                          newArray.splice(index, 1);
                          setAddedCombinationQuestions(newArray);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          <div className="plusCircle mt-4 mb-2">
            <span onClick={handleAddDiv}>+</span>
          </div>
          {isForEcds && <Typography className="text-center">Please Add ESDS Questions</Typography>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={overAllP}
          size="small"
          onChange={(e) => {
            setOverAllP(e.target.value);
          }}
        >
          <MenuItem value={'P1'}>P-1</MenuItem>
          <MenuItem value={'P2'}>P-2</MenuItem>
          <MenuItem value={'P3'}>P-3</MenuItem>
          <MenuItem value={'P4'}>P-4</MenuItem>
          <MenuItem value={'P5'}>P-5</MenuItem>
        </Select>
        <Button variant="outlined" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={checkDisabledStatus() || saving}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CombinationModal;
