import { useState, useEffect } from 'react';
import { NodeToolbar, useStore } from 'reactflow';
import { deleteQuestionOption, attachQuestionByOption, detachQuestionByOption } from 'store/redux/actions/questions';
import { Modal } from 'react-bootstrap';
import { Button } from '@mui/material';
import { useLocation } from 'react-router';
import { getQuestionByDisease } from 'store/redux/actions/questions';
import { FormControl, Input, InputAdornment } from '@mui/material';
import makeToast from 'views/utilities/Toast';
import { displayMultiLangLabelIfPresent } from 'utils/multiLangUtils';
import AreYouSureModal from 'ui-component/AreYouSureModal';

const selectedNodesSelector = (state) =>
  Array.from(state.nodeInternals.values())
    .filter((node) => node.selected)
    .map((node) => node.id);

export default function OptionToolbar(props) {
  const selectedNodeIds = useStore(selectedNodesSelector);
  const [show, setShow] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { state } = useLocation();
  const isVisible = selectedNodeIds.length > 1;
  const allOptions = props?.selectedNode?.data?.question?.options;
  const selectedNodeId = props.selectedNode?.id;
  const selectedOption = allOptions.find((o) => o._id === selectedNodeId);
  const optionForMultiselect = allOptions.find((c) => c.nextQuestion);
  const langInUse = localStorage.getItem('i18nextLng');
  const userRec = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (search === '') setFilteredData([]);
    else {
      filtering();
    }
  }, [search]);

  const deleteOption = async () => {
    let data = {
      currentQuestionId: props?.selectedNode?.data?.question?._id,
      options: props?.selectedNode?.data?.question?.options.filter((e) => e._id !== props.selectedNode?.id),
      optionId: props.selectedNode?.id,
      nextQuestionId: props?.selectedNode?.data?.props?.nextQuestion
    };
    const response = await deleteQuestionOption(data);
    console.log({ response });
    if (response && response?.status) {
      props.populatePath();
      props.deleteOptionProps(props.selectedNode?.id);
    }
  };

  const handleAttach = () => {
    setShow(!show);
    populatePath();
  };

  const filtering = () => {
    let _answers = questions.filter((e) =>
      displayMultiLangLabelIfPresent(e.question, langInUse, e.multiLanguageQuestion)?.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(_answers);
    if (_answers.length === 0) {
      makeToast('error', 'No results found', 'error');
    }
  };

  const populatePath = async () => {
    const response = await getQuestionByDisease({
      diseaseID: state?.diseaseId,
      version: state?.version
    });
    if (response?.status) {
      const multi = optionForMultiselect;
      const question = response.data.find((q) => q._id === multi?.nextQuestion._id);

      question && props?.selectedNode?.data?.question.questionType === 'multiOption'
        ? setQuestions([question])
        : setQuestions(response?.data?.filter((e) => e.isDeleted === false));
      // ?.filter((e) => !e?.preQuestion?.length || !e?.preQuestion)
    } else {
      makeToast('error', 'Unable to fetch questions.');
    }
  };

  const handleModalSubmit = async (condition) => {
    if (condition === 'Attach') {
      let data = {
        currentQuestionId: props?.selectedNode?.data?.question?._id,
        optionId: props.selectedNode?.id,
        nextQuestionId: selectedQuestion?._id
      };
      const option = props?.selectedNode?.data?.question?.options.find((o) => o._id === props.selectedNode?.id);
      const optionIndex = props?.selectedNode?.data?.question?.options.findIndex((o) => o._id === props.selectedNode?.id);
      option.nextQuestionNumber = selectedQuestion?.questionNumber;
      option.nextQuestion = selectedQuestion?._id;
      const options = props?.selectedNode?.data?.question?.options;
      if (props?.selectedNode?.data?.question?.conditionObject) {
        options[optionIndex] = { ...options[optionIndex], condition: props?.selectedNode?.data?.question?.conditionObject };
      }
      options[optionIndex] = option;
      data.options = options;
      const response = await attachQuestionByOption(data);
      if (response && response?.status) {
        props.populatePath();
        setShow(!show);
      }
    }
    if (condition === 'Detach') {
      let data = {
        currentQuestionId: props?.selectedNode?.data?.question?._id,
        optionId: props.selectedNode?.id,
        nextQuestionId: selectedOption?.nextQuestion._id
      };
      const option = props?.selectedNode?.data?.question?.options.find((o) => o._id === props.selectedNode?.id);
      const optionIndex = props?.selectedNode?.data?.question?.options.findIndex((o) => o._id === props.selectedNode?.id);

      option.nextQuestionNumber = -1;
      delete option.nextQuestion;
      if (option?.condition) {
        delete option.condition;
      }

      const options = props?.selectedNode?.data?.question?.options;
      options[optionIndex] = option;
      data.options = options;
      const response = await detachQuestionByOption(data);

      if (response && response?.status) {
        props.deleteDetachEdgeProps(selectedOption?._id);
        props.populatePath();
      }
    }
  };

  return (
    <>
      <NodeToolbar nodeId={selectedNodeIds} isVisible={true}>
        <div className="d-flex flex-row">
          {!props?.selectedNode?.data?.props?.nextQuestion && (
            <div
              className="toolbar-add"
              onClick={() => {
                props.createQuestion(props.selectedNode, 'opt');
              }}
            >
              Add Question
            </div>
          )}
          <div className="toolbar-edit" onClick={() => props.editOption(true)}>
            Edit
          </div>
          {/* <div className="toolbar-del" onClick={() => deleteOption()}>
            Delete
          </div> */}
          {userRec?.userRole != 'author' && (
            <div className="toolbar-del" onClick={() => setIsDeleteModalVisible(true)}>
              Delete
            </div>
          )}
          <div className="toolbar-attach" onClick={() => (selectedOption?.nextQuestion ? handleModalSubmit('Detach') : handleAttach())}>
            {selectedOption?.nextQuestion ? 'Detach' : 'Attach'}
          </div>
        </div>
      </NodeToolbar>

      <Modal
        className="question-modal"
        style={{ height: '90vh', top: 20 }}
        scrollable
        show={show}
        centered
        size="lg"
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Questions List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <FormControl className="answer-multiple">
              <Input
                disableUnderline
                onChange={(e) => setSearch(e.target.value)}
                style={{ fontSize: 12, marginTop: 3 }}
                sx={{ input: { color: 'black' } }}
                value={search}
                placeholder={'Search'}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={require('../../../assets/images/searchIcon.png')} className="logo-smallest" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          {(!filteredData.length ? questions.filter((q) => q._id !== props?.selectedNode?.data?.question?._id) : filteredData)?.map(
            (q, e) => (
              <ul key={e} className={selectedQuestion._id === q._id ? 'active-question' : 'questions-list'}>
                <li onClick={() => setSelectedQuestion(q)}>
                  {displayMultiLangLabelIfPresent(q.question, langInUse, q.multiLanguageQuestion)}
                </li>
              </ul>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outlined" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="contained" onClick={() => handleModalSubmit('Attach')}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <AreYouSureModal
        isInputType={false}
        show={isDeleteModalVisible}
        onhide={() => {
          setIsDeleteModalVisible(false);
        }}
        title={'Delete Option'}
        description={'Are you sure, you want to delete this option'}
        onDelete={() => {
          deleteOption();
          setIsDeleteModalVisible(false);
        }}
      />
    </>
  );
}
