import { GET_ALL_DISEASE, SELECT_DISEASE } from 'store/redux/actions/action.constants';

const initial_state = {
  diseaseListing: null,
  selectedDisease: null
};

export default function (state = initial_state, action) {
  switch (action.type) {
    case GET_ALL_DISEASE:
      return {
        ...state,
        diseaseListing: action.payload
      };
    case SELECT_DISEASE:
      return {
        ...state,
        selectedDisease: action.payload
      };

    default:
      return state;
  }
}
