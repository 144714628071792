import axios from 'axios';
import { Constants } from 'constants/Constants';
import { LocalStorageKeys } from 'constants/LocalStorageKeys';
import { Log } from 'views/utilities/helper';

// import makeToast from "../libs/Toaster";
const request = () => {
    let instance = axios.create({});
    instance.interceptors.request.use(async (config) => {
        var jwtToken = await localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
        config.headers.Authorization = jwtToken ? `${jwtToken}` : '';
        config.headers.Origin = 'http://localhost:3001';
        config.baseURL = Constants.baseURL;
        return config;
    });
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            Log('error.response.data.error', error.response.data.error);
            //   makeToast("error", error.response.data.error);
            if ([401, 403].includes(error.response.status)) {
                window.location.href = '/';
            }
            return error.response;
        }
    );
    return instance;
};

export default request();
