// assets
import { IconTypography, IconShadow, IconWindmill } from '@tabler/icons';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MedicationIcon from '@mui/icons-material/Medication';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// constant
const icons = {
  IconTypography,
  PersonOutlineIcon,
  IconShadow,
  IconWindmill,
  MedicationIcon,
  BookOnlineIcon
};
// const isAdmin = useSelector((state) => state.auth.user.role) === 'admin';
// ==============================|| UTILITIES MENU ITEMS ||============================== //
const utilitiesChildren = [
  {
    id: 'user-list',
    title: 'User List',
    type: 'item',
    url: '/userlist',
    icon: icons.PersonOutlineIcon,
    breadcrumbs: false
  },
  {
    id: 'doctor-list',
    title: 'Doctor List',
    type: 'item',
    url: '/doctorlist',
    icon: icons.MedicationIcon,
    breadcrumbs: false
  },
  {
    id: 'devices-list',
    title: 'Devices List',
    type: 'item',
    url: '/hospital-devices',
    icon: icons.MedicationIcon,
    breadcrumbs: false
  },
  {
    id: 'disease',
    title: 'Pathway Listing',
    type: 'item',
    url: '/pathway-list',
    icon: icons.MedicationIcon,
    breadcrumbs: false
  },
  {
    id: 'question-disease',
    title: 'Clinical Pathways',
    type: 'item',
    url: '/question-list',
    icon: icons.MedicationIcon,
    breadcrumbs: false
  }
];

// const utilitiesChildren1 = [
//   {
//     id: 'user-list',
//     title: 'User List',
//     type: 'item',
//     url: '/userlist',
//     icon: icons.PersonOutlineIcon,
//     breadcrumbs: false
//   },
//   {
//     id: 'devices-list',
//     title: 'Devices List',
//     type: 'item',
//     url: '/hospital-devices',
//     icon: icons.MedicationIcon,
//     breadcrumbs: false
//   },
//   {
//     id: 'disease',
//     title: 'Pathway Listing',
//     type: 'item',
//     url: '/pathway-list',
//     icon: icons.MedicationIcon,
//     breadcrumbs: false
//   },
//   {
//     id: 'question-disease',
//     title: 'Clinical Pathways',
//     type: 'item',
//     url: '/question-list',
//     icon: icons.MedicationIcon,
//     breadcrumbs: false
//   },
//   {
//     id: 'admin-list',
//     title: 'Admin List',
//     type: 'item',
//     url: '/admin-list',
//     icon: icons.PersonOutlineIcon,
//     breadcrumbs: false
//   }
// ];

const utilities = (user) => {
  const { t } = useTranslation();
  return {
    id: 'utilities',
    type: 'group',
    children: [
      {
        id: 'devices-list',
        title: t('Devices_List'),
        type: 'item',
        url: '/hospital-devices',
        icon: icons.MedicationIcon,
        breadcrumbs: false
      },
      {
        id: 'disease',
        title: t('Pathway_Listing'),
        type: 'item',
        url: '/pathway-list',
        icon: icons.MedicationIcon,
        breadcrumbs: false
      },
      // {
      //   id: 'question-disease',
      //   title: t('Clinical_Pathways'),
      //   type: 'item',
      //   url: '/question-list',
      //   icon: icons.MedicationIcon,
      //   breadcrumbs: false
      // },

      {
        id: 'public-health-questions',
        title: 'Public Health Questions',
        type: 'item',
        url: '/public-health-questions',
        icon: icons.PersonOutlineIcon,
        breadcrumbs: false
      },
      {
        id: 'admin-list',
        title: t('Admin_List'),
        type: 'item',
        url: '/admin-list',
        icon: icons.PersonOutlineIcon,
        breadcrumbs: false
      },
      {
        id: 'incidents',
        title: 'Incidents',
        type: 'item',
        url: '/incidents',
        icon: icons.MedicationIcon,
        breadcrumbs: false
      },
      {
        id: 'feedback',
        title: 'Feedback',
        type: 'item',
        url: '/feedback',
        icon: icons.MedicationIcon,
        breadcrumbs: false
      }
    ]
  };

  // children: [
  //     // {
  //     //     id: 'util-typography',
  //     //     title: 'Login',
  //     //     type: 'item',
  //     //     url: '/utils/util-typography',
  //     //     icon: icons.IconTypography,
  //     //     breadcrumbs: false
  //     // },
  //     {
  //         id: 'user-list',
  //         title: 'User List',
  //         type: 'item',
  //         url: '/userlist',
  //         icon: icons.PersonOutlineIcon,
  //         breadcrumbs: false
  //     },

  //     {
  //         id: 'doctor-list',
  //         title: 'Doctor List',
  //         type: 'item',
  //         url: '/doctorlist',
  //         icon: icons.MedicationIcon,
  //         breadcrumbs: false
  //     }
  //     // {
  //     //     id: 'hospital-list',
  //     //     title: 'Hospital List',
  //     //     type: 'item',
  //     //     url: '/hospital',
  //     //     icon: icons.MedicationIcon,
  //     //     breadcrumbs: false
  //     // },
  //     // {
  //     //     id: 'disease',
  //     //     title: 'Disease Listing',
  //     //     type: 'item',
  //     //     url: '/disease-list',
  //     //     icon: icons.MedicationIcon,
  //     //     breadcrumbs: false
  //     // },
  //     // {
  //     //     id: 'question-disease',
  //     //     title: 'Clinical Pathways',
  //     //     type: 'item',
  //     //     url: '/question-list',
  //     //     icon: icons.MedicationIcon,
  //     //     breadcrumbs: false
  //     // }
  //     // {
  //     //     id: 'clinical-path',
  //     //     title: 'Clinical path',
  //     //     type: 'item',
  //     //     url: '/clinical-path',
  //     //     icon: icons.MedicationIcon,
  //     //     breadcrumbs: false
  //     // }
  //     // {
  //     //     id: 'appointment-list',
  //     //     title: 'Appointment List',
  //     //     type: 'item',
  //     //     url: '/appointmentlist',
  //     //     icon: icons.BookOnlineIcon,
  //     //     breadcrumbs: false
  //     // },
  //     // {
  //     //     id: 'util-shadow',
  //     //     title: 'Shadow',
  //     //     type: 'item',
  //     //     url: '/utils/util-shadow',
  //     //     icon: icons.IconShadow,
  //     //     breadcrumbs: false
  //     // },
  //     // {
  //     //     id: 'icons',
  //     //     title: 'Icons',
  //     //     type: 'collapse',
  //     //     icon: icons.IconWindmill,
  //     //     children: [
  //     //         {
  //     //             id: 'tabler-icons',
  //     //             title: 'Tabler Icons',
  //     //             type: 'item',
  //     //             url: '/icons/tabler-icons',
  //     //             breadcrumbs: false
  //     //         },
  //     //         {
  //     //             id: 'material-icons',
  //     //             title: 'Material Icons',
  //     //             type: 'item',
  //     //             url: '/icons/material-icons',
  //     //             breadcrumbs: false
  //     //         }
  //     //     ]
  //     // }
  // ]
};

const utilitiesHospital = {
  id: 'utilities',
  type: 'group',
  children: [
    // {
    //     id: 'util-typography',
    //     title: 'Login',
    //     type: 'item',
    //     url: '/utils/util-typography',
    //     icon: icons.IconTypography,
    //     breadcrumbs: false
    // },
    {
      id: 'user-list',
      title: 'User List',
      type: 'item',
      url: '/userlist',
      icon: icons.PersonOutlineIcon,
      breadcrumbs: false
    },
    {
      id: 'doctor-list',
      title: 'Doctor List',
      type: 'item',
      url: '/doctorlist',
      icon: icons.MedicationIcon,
      breadcrumbs: false
    },
    {
      id: 'devices-list',
      title: 'Device List',
      type: 'item',
      url: '/hospital-devices',
      icon: icons.MedicationIcon,
      breadcrumbs: false
    }
    // {
    //     id: 'disease',
    //     title: 'Disease',
    //     type: 'item',
    //     url: '/disease-list',
    //     icon: icons.MedicationIcon,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'question-disease',
    //     title: 'Disease Questions',
    //     type: 'item',
    //     url: '/question-list',
    //     icon: icons.MedicationIcon,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'clinical-path',
    //     title: 'Clinical path',
    //     type: 'item',
    //     url: '/clinical-path',
    //     icon: icons.MedicationIcon,
    //     breadcrumbs: false
    // }
    // {
    //     id: 'appointment-list',
    //     title: 'Appointment List',
    //     type: 'item',
    //     url: '/appointmentlist',
    //     icon: icons.BookOnlineIcon,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'util-shadow',
    //     title: 'Shadow',
    //     type: 'item',
    //     url: '/utils/util-shadow',
    //     icon: icons.IconShadow,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'icons',
    //     title: 'Icons',
    //     type: 'collapse',
    //     icon: icons.IconWindmill,
    //     children: [
    //         {
    //             id: 'tabler-icons',
    //             title: 'Tabler Icons',
    //             type: 'item',
    //             url: '/icons/tabler-icons',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'material-icons',
    //             title: 'Material Icons',
    //             type: 'item',
    //             url: '/icons/material-icons',
    //             breadcrumbs: false
    //         }
    //     ]
    // }
  ]
};

export default utilities;
