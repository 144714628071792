const keys = ['low', 'medium', 'high'];

export default {
  light: keys.map((key) => ({ key, data: require(`./light/${key}.png`) })),
  dark: keys.map((key) => ({ key, data: require(`./dark/${key}.png`) })),
  placeholder: require('./no-icon.png')
};

export const LIGHT_EMOJI = {
  [keys[0]]: require(`./light/${keys[0]}.png`),
  [keys[1]]: require(`./light/${keys[1]}.png`),
  [keys[2]]: require(`./light/${keys[2]}.png`)
};

export const DARK_EMOJI = {
  [keys[0]]: require(`./dark/${keys[0]}.png`),
  [keys[1]]: require(`./dark/${keys[1]}.png`),
  [keys[2]]: require(`./dark/${keys[2]}.png`)
};
