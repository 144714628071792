import { GET_ALL_QUESTION_DISEASEID, CLEAR_QUESTION_LIST } from 'store/redux/actions/action.constants';

export default function (state = [], action) {
  switch (action.type) {
    case GET_ALL_QUESTION_DISEASEID:
      return action.payload;
    case CLEAR_QUESTION_LIST:
      return [];

    default:
      return state;
  }
}
