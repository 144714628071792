import React, { useState } from 'react';
import { Button, Typography, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Modal, Toast } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import medicalSupport1 from '../../../assets/images/medical-support_1.png';
import UploadImg from '../../../assets/images/upload.svg';
import crossImg from '../../../assets/images/cross.svg';
import './style.scss';
import makeToast from 'utils/Toaster';
import { createJiraTicket } from 'store/redux/actions/incidents';

const steps = ['Incident Details', 'User Information', 'Reporting Authority'];

const ClinicalIncidentFormModal = () => {
  const [clinicalIncidentFormModal, setClinicalIncidentFormModal] = useState(false);
  //USER
  const [userData] = useState(() => JSON.parse(localStorage.getItem('user')));
  const [myBucket] = useState(() => {
    const { userS3Config } = userData;
    AWS.config.update({
      accessKeyId: userS3Config.REACT_APP_ACCESS_KEY,
      secretAccessKey: userS3Config.REACT_APP_SECRET_ACCESS_KEY
    });
    return new AWS.S3({
      params: { Bucket: userS3Config.REACT_APP_S3_BUCKET },
      region: userS3Config.REACT_APP_S3_REGION
    });
  });

  //STEP 1
  const [incidentDate, setIncidentDate] = useState(new Date());
  const [incidentTime, setIncidentTime] = useState(new Date());
  const [optoRefrenceNumber, setOptoRefrenceNumber] = useState('');
  const [areaOfIncident, setAreaOfIncident] = useState('');

  //STEP 2
  const [impactAndSeverity, setImpactAndSeverity] = useState('');
  const [immediateActions, setImmediateActions] = useState('');
  const [followUpActions, setFollowUpActions] = useState('');
  const [pdfFile, setPdfFile] = useState();

  //STEP 3
  const [reportedBy, setReportedBy] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    jobTitle: ''
  });
  const [investigator, setInvestigator] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    jobTitle: ''
  });

  const handleChangeReportedBy = (e) => setReportedBy((p) => ({ ...p, [e.target.name]: e.target.value }));
  const handleChangeInvestigationLead = (e) => setInvestigator((p) => ({ ...p, [e.target.name]: e.target.value }));

  //UTILS
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;

  const submitData = async () => {
    console.log('HEYY OHH');
    try {
      const dateAndTime = new Date(
        incidentDate.getFullYear(),
        incidentDate.getMonth(),
        incidentDate.getDate(),
        incidentTime.getHours(),
        incidentTime.getMinutes()
      );

      const objToSend = {
        issueDetails: {
          fields: {
            project: {
              key: 'DUM'
            },
            issuetype: {
              name: 'Bug'
            },
            summary: impactAndSeverity,
            description: `Name: ${reportedBy.name}\nEmail: ${reportedBy.email}\nContact-Number: ${reportedBy.phoneNumber}\nJob-Role: ${reportedBy.jobTitle}\nArea Of Incident: ${areaOfIncident}\nDate & Time: ${dateAndTime}\nReference Number: ${optoRefrenceNumber}\nCategory: Bug\nImmediate Actions: ${immediateActions}\nFollow-up Actions: ${followUpActions}`
          }
        },
        hospital: userData.hospital,
        user: userData._id,
        category: 'Bug',
        refNumber: optoRefrenceNumber,
        dateTime: dateAndTime,
        areaOfIncident: areaOfIncident,
        // userRole,
        immediateActions,
        followUpActions,
        name: reportedBy.name,
        email: reportedBy.email,
        contactNumber: reportedBy.phoneNumber,
        jobRole: reportedBy.jobTitle,
        investigator,
        // isreportingAuthority,
        issuetype: 'Clinical'
        // description: incidentDetails
      };

      if (pdfFile) {
        myBucket
          .putObject({
            Body: pdfFile,
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: pdfFile.name
          })
          .on('success', async (res) => {
            const url = 'https://' + process.env.REACT_APP_S3_BUCKET + '.s3.amazonaws.com/' + pdfFile.name;
            console.log('success', res);
            if (res?.httpResponse?.statusCode === 200) {
              makeToast(t('success'), t('Attachement uploaded successfully'));
              objToSend.pdfAttachement = encodeURI(url);
              objToSend.issueDetails.fields.description += `\nPDF Attachment: ${encodeURI(url)}`;
              console.log('OBJ TO SEND => ', objToSend);
              const res = await createJiraTicket(objToSend);
              console.log('RES => ', res);
              setClinicalIncidentFormModal(false);
              clearStates();
            } else {
              makeToast(t('error'), t('Failed to upload attachement'));
            }
          })
          .send((err) => {
            if (err) makeToast(t('error'), t('Failed to upload attachement'));
          });
      } else {
        console.log('OBJ TO SEND => ', objToSend);
        const res = await createJiraTicket(objToSend);
        console.log('RES => ', res);
        setClinicalIncidentFormModal(false);
        clearStates();
      }
    } catch (error) {
      console.log('ERROR => ', error);
    }
  };

  const handlePdfChange = (e) => {
    const [file] = e.target.files;
    if (file) setPdfFile(file);
  };

  const handleNext = () => {
    if (isLastStep) {
      submitData();
      setClinicalIncidentFormModal(false);
      setActiveStep(0);
    } else {
      if (activeStep == 0 && (!optoRefrenceNumber || !areaOfIncident)) {
        makeToast('error', 'Fill in all the info to continue');
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const clearStates = () => {
    setIncidentDate(new Date());
    setIncidentTime(new Date());
    setOptoRefrenceNumber();
    setAreaOfIncident();
    setImpactAndSeverity('');
    setImmediateActions('');
    setFollowUpActions('');
    setReportedBy({
      name: '',
      email: '',
      phoneNumber: '',
      jobTitle: ''
    });
    setInvestigator({
      name: '',
      email: '',
      phoneNumber: '',
      jobTitle: ''
    });
    setActiveStep(0);
    setPdfFile();
  };

  const _renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4 mb-5 pb-5 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '20px' }}>
                Incident Details
              </Typography>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong>Incident Date</strong>
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={new Date(incidentDate).toISOString().split('T')[0]}
                  onChange={(e) => {
                    setIncidentDate(new Date(e.target.value));
                  }}
                />
              </div>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong>Incident Time</strong>
                </label>
                <input
                  type="time"
                  className="form-control"
                  value={incidentTime.toLocaleTimeString('en-US', {
                    hour12: false,
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                  onChange={(e) => {
                    const timeString = e.target.value;
                    const currentDate = new Date();
                    const timeParts = timeString.split(':');
                    currentDate.setHours(parseInt(timeParts[0], 10));
                    currentDate.setMinutes(parseInt(timeParts[1], 10));
                    setIncidentTime(currentDate);
                  }}
                />
              </div>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong>Opto Reference Number</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="insert Opto unique reference number"
                  value={optoRefrenceNumber}
                  onChange={(e) => setOptoRefrenceNumber(e.target.value)}
                />
                <Typography className="mt-2" variant="body1" sx={{ color: '#585858', marginBottom: '10px' }}>
                  "You can find your Opto Reference Number on the Triage Data Sheet (pdf)"
                </Typography>
              </div>
              <div className="form-group">
                <label>
                  <strong>Area or clinical pathway of Incident</strong>
                </label>
                <Typography variant="body2" sx={{ color: '#034562', marginBottom: '10px' }}>
                  Specify the area or clinical pathway of the Opto Health platform where the incident occurred?
                </Typography>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify area here.."
                  value={areaOfIncident}
                  onChange={(e) => setAreaOfIncident(e.target.value)}
                />
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '20px' }}>
                Incident Details
              </Typography>
              <div className="form-group">
                <label>
                  <strong>Impact and Severity</strong>
                </label>
                <Typography variant="body2" sx={{ color: '#034562', marginBottom: '10px' }}>
                  How did the incident impact operations or patient care?
                </Typography>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify impact and severity here.."
                  value={impactAndSeverity}
                  onChange={(e) => setImpactAndSeverity(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  <strong>Immediate Actions Taken</strong>
                </label>
                <Typography variant="body2" sx={{ color: '#034562', marginBottom: '10px' }}>
                  Describe the initial actions taken to address the incident
                </Typography>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify actions here.."
                  value={immediateActions}
                  onChange={(e) => setImmediateActions(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  <strong>Attachments</strong>
                </label>
                <Typography variant="body2" sx={{ color: '#034562', marginBottom: '10px' }}>
                  Upload the Opto Clinical PDF. Patient details must be redacted for data protection.
                  <a
                    href="#"
                    style={{
                      textDecoration: 'none'
                    }}
                  >
                    {' '}
                    How to redact a pdf{' '}
                  </a>
                </Typography>

                <div className="Input_upload_area d-flex flex-column gap-3  justify-content-center align-items-center cursor-pointer">
                  <div className="Input_upload_area_content d-flex flex-column gap-2 justify-content-center align-items-center cursor-pointer">
                    <img
                      src={UploadImg}
                      alt="Preview Image"
                      id="previewImage"
                      className="drop-zoon__preview-image cursor-pointer"
                      draggable="false"
                    />
                    <Typography variant="body2" sx={{ color: '#36AFFF', marginBottom: '10px' }}>
                      {pdfFile ? (
                        <>
                          {pdfFile.name}
                          <IconButton aria-label="delete" size="small" color="primary" onClick={(e) => setPdfFile()}>
                            <CancelIcon />
                          </IconButton>
                        </>
                      ) : (
                        <>Drag & Drop File here or upload</>
                      )}
                    </Typography>
                  </div>
                  {!pdfFile && (
                    <input type="file" id="fileInput" className="Input_upload_area_file-input cursor-pointer" onChange={handlePdfChange} />
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>
                  <strong>Follow-up Actions</strong>
                </label>
                <Typography variant="body2" sx={{ color: '#034562', marginBottom: '10px' }}>
                  What follow-up actions are planned or have been taken to prevent a recurrence?
                </Typography>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify follow-up actions here.."
                  value={followUpActions}
                  onChange={(e) => setFollowUpActions(e.target.value)}
                />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '20px' }}>
                Incident reported by
              </Typography>
              <div className="form-group">
                <label className="mb-1">
                  <strong>Name</strong>
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={reportedBy.name}
                  onChange={handleChangeReportedBy}
                />
              </div>
              <div className="form-group">
                <label className="mb-1">
                  <strong>Job title</strong>
                </label>
                <input
                  name="jobTitle"
                  type="text"
                  className="form-control"
                  placeholder="Doctor, Nurse, Administrator"
                  value={reportedBy.jobTitle}
                  onChange={handleChangeReportedBy}
                />
              </div>
              <div className="form-group">
                <label className="mb-1">
                  <strong>Email address</strong>
                </label>
                <input
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder="name@email.com"
                  value={reportedBy.email}
                  onChange={handleChangeReportedBy}
                />
              </div>
              <div className="form-group">
                <label className="mb-1">
                  <strong>Phone number</strong>
                </label>
                <input
                  name="phoneNumber"
                  type="text"
                  className="form-control"
                  placeholder="+00 00000000000"
                  value={reportedBy.phoneNumber}
                  onChange={handleChangeReportedBy}
                />
              </div>
            </div>
            <div className="form-group d-flex flex-column gap-0 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '20px' }}>
                Ongoing Investigation Lead
              </Typography>
              <div className="form-group">
                <label className="mb-1">
                  <strong>Name</strong>
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={investigator.name}
                  onChange={handleChangeInvestigationLead}
                />
              </div>
              <div className="form-group">
                <label className="mb-1">
                  <strong>Job title</strong>
                </label>
                <input
                  name="jobTitle"
                  type="text"
                  className="form-control"
                  placeholder="Doctor, Nurse, Administrator"
                  value={investigator.jobTitle}
                  onChange={handleChangeInvestigationLead}
                />
              </div>
              <div className="form-group">
                <label className="mb-1">
                  <strong>Email address</strong>
                </label>
                <input
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder="name@email.com"
                  value={investigator.email}
                  onChange={handleChangeInvestigationLead}
                />
              </div>
              <div className="form-group">
                <label className="mb-1">
                  <strong>Phone number</strong>
                </label>
                <input
                  name="phoneNumber"
                  type="text"
                  className="form-control"
                  placeholder="+00 00000000000"
                  value={investigator.phoneNumber}
                  onChange={handleChangeInvestigationLead}
                />
              </div>
            </div>
          </>
        );
      default:
        return <div>Not Found</div>;
    }
  };

  return (
    <>
      <div
        onClick={() => setClinicalIncidentFormModal(true)}
        className="incident-page d-flex justify-content-start align-items-center gap-4 w-75 cursor-pointer"
      >
        <div className="icon">
          <img src={medicalSupport1} alt="img" width="60" />
        </div>
        <div className="d-flex gap-1 flex-column justify-content-start">
          <Typography className="p-0 m-0" variant="h4" sx={{ color: '#36AFFF' }}>
            Report an Clinical Incident
          </Typography>
          <Typography className="p-0 m-0" variant="subtitle2" sx={{ color: '#034562' }}>
            Let us know if something is not working properly and we'll aim to get it back up and running quickly.
          </Typography>
        </div>
      </div>
      <Modal className="modal-opto" centered show={clinicalIncidentFormModal}>
        <div className="modal-content">
          <Modal.Header>
            <Modal.Title className="modal-title">Clinical Incident Form</Modal.Title>
            <Button
              onClick={() => {
                setClinicalIncidentFormModal(false);
                setActiveStep(0);
              }}
              className="close"
            >
              <img src={crossImg} alt="img" width="20" height="20" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center h-100 align-items-center">
              <div className="auth-form col-md-12 px-5">
                {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>Step {activeStep + 1}</Typography> */}
                {_renderStepContent(activeStep)}

                <div className="d-flex gap-3 justify-content-center mb-4">
                  {activeStep !== 0 && (
                    <button className="btn btn-light text-secondary btn-block px-4" onClick={handleBack}>
                      Back
                    </button>
                  )}
                  <button className="btn btn-primary btn-block px-4" onClick={handleNext}>
                    {isLastStep ? 'Submit' : 'Next'}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ClinicalIncidentFormModal;
