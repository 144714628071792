import { Constants } from 'constants/Constants';
import request from 'utils/request';
import { Log } from 'views/utilities/helper';
import makeToast from 'views/utilities/Toast';
import { GET_ALL_DISEASE } from '../action.constants';

export const getAllDiseaseListing = async () => {
  try {
    const res = await request.get(Constants.getDiseaseList_api_url);
    if (res) {
      return res.data;
    }
  } catch (e) {
    makeToast('error', e.message);
  }
};

export const getAllDiseases = async () => {
  try {
    const res = await request.get(Constants.getAllDiseases_api_url);
    if (res) {
      return res.data;
    }
  } catch (e) {
    makeToast('error', e.message);
  }
};

export const getDiseaseListingByHospitalId = async (id) => {
  try {
    const res = await request.post(Constants.getDiseaseListByHospitalId_api_url, { createdByHospital: id });
    if (res) {
      return res.data;
    }
  } catch (e) {
    makeToast('error', e.message);
  }
};

export const getDiseaseById = async (id) => {
  try {
    const res = await request.post(Constants.getDiseaseById, { id });
    if (res) {
      return res.data;
    }
  } catch (e) {
    makeToast('error', e.message);
  }
};

export const getAllFilteredDiseases = async (data) => {
  try {
    const res = await request.post(Constants.getFilteredDiseaseList_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    makeToast('error', e.message);
  }
};

export const createDisease = async (data) => {
  try {
    // console.log('add pathway data', data);
    let user = JSON.parse(localStorage.getItem('user'));
    const res = await request.post(Constants.createDisease_api_url, { ...data, createdByHospital: user.hospital || null });

    if (res) {
      return res.data;
    }
  } catch (e) {
    // Log('createDoctor error', e);
    makeToast('error', e.message);
  }
};

export const createDiseaseByBodyPart = async (data) => {
  try {
    console.log('update disease body part data', data);
    let user = JSON.parse(localStorage.getItem('user'));
    const res = await request.post(Constants.createDiseaseByBodyPart_api_url, { ...data, createdByHospital: user.hospital || null });

    if (res) {
      return res.data;
    }
  } catch (e) {
    // Log('createDoctor error', e);
    makeToast('error', e.message);
  }
};

export const updateDiseaseByBodyPart = async (data) => {
  try {
    console.log('update disease body part data', data);
    const res = await request.post(Constants.updateDiseaseByBodyPart_api_url, data);

    if (res) {
      return res.data;
    }
  } catch (e) {
    // Log('createDoctor error', e);
    makeToast('error', e.message);
  }
};

export const updateDisease = async (userdata) => {
  try {
    // console.log('update pathway data', userdata);
    const res = await request.post(Constants.updateDisease_api_url, userdata);
    if (res) {
      return res.data;
    }
  } catch (e) {
    // Log('createDoctor error', e);
    makeToast('error', e.message);
  }
};
