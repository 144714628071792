import { useState } from 'react';
import { IconButton, MenuItem, Select, Typography, Stack, Button, TextField, Fab } from '@mui/material';
import { Modal, Form } from 'react-bootstrap';
import { getLangFullName, getLanguageRtlStatus } from 'utils/multiLangUtils';
import { useTranslation } from 'react-i18next';
import emojies, { LIGHT_EMOJI, DARK_EMOJI } from 'assets/emojies';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import EmojiSelector from 'components/EmojiSelector';

const EditOptionsModal = ({
  optionBeingEdited,
  multiLangOptionTextForEdit,
  handleMultiLangOptionText,
  pForEdit,
  setPEdit,
  isPainScoreQuestion,
  handleEditOptionSubmit,
  closeEditModal
}) => {
  const { t } = useTranslation();

  const [customEmojiDark, setCustomEmojiDark] = useState();
  const [customEmojiLight, setCustomEmojiLight] = useState();

  const [emoji, setEmoji] = useState({
    light: optionBeingEdited?.painScoreDetails?.selectedEmojiForLightMode,
    dark: optionBeingEdited?.painScoreDetails?.selectedEmojiForDarkMode
  });

  const getMultiLangOptionValue = (lang) => {
    let objFound = multiLangOptionTextForEdit.find((obj) => obj.language === lang);
    if (objFound) {
      return objFound;
    } else {
      return '';
    }
  };

  return (
    <Modal show onHide={closeEditModal} centered size="md" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Option</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Enter new text for this option</p>
        <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
          {multiLangOptionTextForEdit.map((opt, i) => {
            return (
              <Form.Control
                key={i}
                maxLength={100}
                className="custom-input"
                value={getMultiLangOptionValue(opt.language)?.content}
                onChange={(e) => handleMultiLangOptionText(opt.language, e.target.value)}
                placeholder={getLangFullName(opt.language)}
                style={{ textAlign: getLanguageRtlStatus(opt.language) }}
              />
            );
          })}
          <Select
            placeholder="aaa"
            size="small"
            value={pForEdit}
            onChange={(e) => {
              if (e.target.value !== 'a') {
                setPEdit(e.target.value);
              }
            }}
          >
            <MenuItem defaultChecked value="a">
              <em>{t('Select_P')}</em>
            </MenuItem>
            {['P1', 'P2', 'P3', 'P4', 'P5'].map((ev) => (
              <MenuItem key={ev} value={ev}>
                {ev}
              </MenuItem>
            ))}
          </Select>
        </div>

        {/* {isPainScoreQuestion && (
          <Stack alignItems="center" gap={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography>Light</Typography>
              {emojies.light?.map((e) => (
                <Fab
                  key={e.key}
                  color={`${emoji.light === e.key ? 'error' : 'default'}`}
                  onClick={() => {
                    setEmoji((p) => ({ ...p, light: e.key }));
                  }}
                >
                  <img src={e.data} alt="emoji" width={50} height={50} />
                </Fab>
              ))}
              <EmojiSelector
                id={'custom-emoji-light'}
                name="light"
                emoji={customEmojiLight}
                setEmoji={setCustomEmojiLight}
                setSelectedEmoji={setEmoji}
                selectedEmoji={emoji.light}
              />
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography>Dark</Typography>
              {emojies.dark?.map((e) => {
                return (
                  <Fab
                    key={e.key}
                    color={`${emoji.dark === e.key ? 'error' : 'default'}`}
                    onClick={() => {
                      setEmoji((p) => ({ ...p, dark: e.key }));
                    }}
                  >
                    <img src={e.data} alt="emoji" width={50} height={50} />
                  </Fab>
                );
              })}
              <EmojiSelector
                id={'custom-emoji-dark'}
                name="dark"
                emoji={customEmojiDark}
                setEmoji={setCustomEmojiDark}
                setSelectedEmoji={setEmoji}
                selectedEmoji={emoji.dark}
              />
            </Stack>
          </Stack>
        )} */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={closeEditModal}>
          Close
        </Button>
        <Button variant="contained" onClick={() => handleEditOptionSubmit(emoji)}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOptionsModal;
