import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import auth from './redux/reducers/auth/index';
import doctor from './redux/reducers/doctor';
import user from './redux/reducers/user/index';
import disease from './redux/reducers/disease';
import question from './redux/reducers/question';
import hospital from './redux/reducers/hospital';
import hospitalDevices from './redux/reducers/hospitalDevices';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    auth: auth,
    user: user,
    doctor: doctor,
    disease: disease,
    question: question,
    hospital: hospital,
    hospitalDevices: hospitalDevices
});

export default reducer;
