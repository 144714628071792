import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import adminUtilities from './adminUtilitis';
import other from './other';
import authorUtilities from './authorUtilities';

// ==============================|| MENU ITEMS ||============================== //
const isAdmin = false;
const menuItems = (user) => {
  if (user?.userRole === 'opto_supper_admin' || user?.userRole === 'opto_admin') {
    return {
      items: [dashboard(), pages, adminUtilities()]
    };
  } else if (user?.userRole === 'author') {
    return { items: [dashboard(), pages, authorUtilities()] };
  } else {
    return {
      items: [dashboard(), pages, utilities(user)]
    };
  }
};

export default menuItems;
