import { Constants } from '../constants/Constants';
import _ from 'lodash';

const getLanguageRtlStatus = (lang) => {
  const langObj = Constants.languages.filter((obj) => obj.value === lang);
  if (langObj[0].isRTL) {
    return 'right';
  } else {
    return 'left';
  }
};
const getLangFullName = (lang) => {
  const langObjFound = Constants.languages.filter((obj) => obj.value === lang);
  if (langObjFound) {
    return langObjFound[0].langName;
  } else {
    return 'Not recognised';
  }
  return 'failed';
};
const setMultiLangArrayFromData = (baseValue, setBaseValue, baseLang, multiLangArrayRecieved) => {
  console.log(multiLangArrayRecieved);
  const arrayRecieved = [...multiLangArrayRecieved];

  if (arrayRecieved?.length === 0) {
    let arr;
    if (baseValue) {
      arr = [{ language: baseLang, content: baseValue }];
    } else {
      arr = [];
    }
    if (setBaseValue != null) {
      setBaseValue('');
    }
    return arr;
  } else {
    const payload = [...multiLangArrayRecieved];
    const objFound = payload.find((obj) => obj.language === baseLang);
    if (!objFound) {
      if (baseValue) {
        payload.push({ language: baseLang, content: baseValue });
      }
      if (setBaseValue != null) {
        setBaseValue('');
      }
    } else {
      if (setBaseValue != null) {
        setBaseValue('');
      }
    }
    return payload;
  }
};

const setMultiLangArrayFromDataForOptions = (baseValue, baseLang, multiLangArrayRecieved) => {
  const arrayRecieved = [...multiLangArrayRecieved];
  if (arrayRecieved?.length === 0) {
    const arr = [{ language: baseLang, content: baseValue }];
    return arr;
  } else {
    const payload = [...multiLangArrayRecieved];
    const objFound = payload.find((obj) => obj.language === baseLang);
    if (!objFound) {
      payload.push({ language: baseLang, content: baseValue });
    }
    return payload;
  }
};

const displayMultiLangLabelIfPresent = (baseKey, lang, multiLangKey) => {
  if (multiLangKey) {
    if (multiLangKey.length === 0) {
      return baseKey;
    } else {
      const objFound = multiLangKey?.find((obj) => obj.language === lang);
      if (objFound) {
        return objFound.content;
      } else {
        return multiLangKey[0].content;
        //return baseKey;
      }
    }
  } else {
    return baseKey;
  }
};
const checkIfLanguageIsPresentInMultiLang = (lang, multiLangKey) => {
  const arrayLang = [...multiLangKey];
  if (arrayLang.length === 0) {
    return false;
  } else {
    const objFound = arrayLang.find((obj) => obj.language === lang);
    if (objFound) {
      return true;
    } else {
      return false;
    }
  }
};

const checkIfAnyLanguageIsPresentInMultiLang = (multiLangKey) => {
  if (!multiLangKey) {
    return false;
  }
  const arrayLang = [...multiLangKey];
  if (arrayLang.length === 0) {
    return false;
  } else {
    return true;
  }
};

const sortMultiLangDataAlphabetically = (arr, multiLangKeyName, baseKeyName, lang) => {
  const arrRecieved = [...arr];
  arrRecieved.sort((obj1, obj2) => {
    let contentA;
    let contentB;

    if (obj1[multiLangKeyName] && obj1[multiLangKeyName].length > 1) {
      const langObj1 = obj1[multiLangKeyName].find((obj) => obj.language === lang);
      contentA = langObj1.content.toUpperCase();
    } else if (obj1[multiLangKeyName] && obj1[multiLangKeyName].length == 1) {
      const langObj1 = obj1[multiLangKeyName][0];
      contentA = langObj1.content.toUpperCase();
    } else {
      contentA = obj1[baseKeyName];
    }

    if (obj2[multiLangKeyName] && obj2[multiLangKeyName].length > 1) {
      const langObj2 = obj2[multiLangKeyName].find((obj) => obj.language === lang);
      contentB = langObj2.content.toUpperCase();
    } else if (obj2[multiLangKeyName] && obj2[multiLangKeyName].length == 1) {
      const langObj2 = obj2[multiLangKeyName][0];
      contentB = langObj2.content.toUpperCase();
    } else {
      contentB = obj2[baseKeyName];
    }

    if (contentA < contentB) {
      return -1;
    } else if (contentA > contentB) {
      return 1;
    }
    return 0;
  });
  return arrRecieved;
};
const compareObjects = (obj1, obj2) => {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  if (typeof obj1 === 'object' && obj1 !== null && obj2 !== null) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key) || !compareObjects(obj1[key], obj2[key])) {
        return false;
      }
    }
  } else if (obj1 !== obj2) {
    return false;
  }

  return true;
};

const sortByLanguage = (data, language) => {
  return _.sortBy(data, (item) => {
      const languageContent = item.multiLanguageDiseaseName.find(lang => lang.language === language);
      return languageContent ? languageContent.content : '';
  });
};

export {
  getLanguageRtlStatus,
  getLangFullName,
  setMultiLangArrayFromData,
  setMultiLangArrayFromDataForOptions,
  displayMultiLangLabelIfPresent,
  checkIfLanguageIsPresentInMultiLang,
  checkIfAnyLanguageIsPresentInMultiLang,
  sortMultiLangDataAlphabetically,
  compareObjects,
  sortByLanguage,
};
