import { MenuItem, Select, Typography } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useLocation } from 'react-router';
import makeToast from 'utils/Toaster';
import '../ClinicalPath/styles.css';
import { getQuestionByDisease } from 'store/redux/actions/questions';
import { DataGrid } from '@mui/x-data-grid';

export default function QuestionDetails() {
    const { state } = useLocation();
    const [options, setOptions] = useState([]);
    const [editOption, setEditOption] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [questionsList, setQuestionsList] = useState([]);

    const getStateDisease = async () => {
        const response = await getQuestionByDisease({ diseaseID: state?.question?.diseaseID });

        if (response && response?.status) {
            setQuestionsList(response.data);
        }
    };

    useEffect(() => {
        if (state?.question) {
            getStateDisease();
        }
    }, [state]);

    // const columns = [
    //     {
    //         name: 'Option',
    //         selector: (row) => row.option,
    //         sortable: true,
    //         reorder: true,
    //         width: '180px',
    //         wrap: true
    //         // center: true
    //     },
    //     {
    //         name: 'Code',
    //         selector: (row) => row.code,
    //         sortable: true,
    //         reorder: true,
    //         width: '180px'
    //         // center: true
    //     },

    //     {
    //         name: 'Next Question',
    //         selector: (row) => row.nextQuestion,
    //         sortable: true,
    //         reorder: true,
    //         width: '180px'
    //         // center: true
    //     },

    //     {
    //         name: 'Action',
    //         selector: (row) => row.action,
    //         sortable: true,
    //         reorder: true,
    //         width: '180px'
    //         // center: true
    //     }
    // ];

    // const makeRow = (userArr) => {
    //     var arr =
    //         Array.isArray(userArr) && userArr.length > 0
    //             ? userArr.map((data, id) => ({
    //                   option: (
    //                       <div className="date">
    //                           <h5>{data.option}</h5>
    //                       </div>
    //                   ),
    //                   code: (
    //                       <div className="date">
    //                           <h5>{data.code}</h5>
    //                       </div>
    //                   ),
    //                   nextQuestion: data.nextQuestion ? (
    //                       <div className="date">
    //                           <h5>{data?.nextQuestion?.question}</h5>
    //                       </div>
    //                   ) : editOption ? (
    //                       <Select
    //                           value={selectedOption}
    //                           label="Next Question"
    //                           onChange={(e) => {
    //                               setSelectedOption(e.target.value);
    //                           }}
    //                       >
    //                           <MenuItem disabled value="">
    //                               <em>Select Next Question</em>
    //                           </MenuItem>
    //                           {questionsList?.map((data) => (
    //                               <MenuItem key={data} value={data}>
    //                                   {data?.question}
    //                               </MenuItem>
    //                           ))}
    //                       </Select>
    //                   ) : (
    //                       <div className="date">
    //                           <h5>Null</h5>
    //                       </div>
    //                   ),
    //                   action: (
    //                       <div className="d-flex align-items-center">
    //                           <button
    //                               className="edit-btn"
    //                               onClick={() => {
    //                                   console.log(1, { editOption });
    //                                   setEditOption(editOption ? false : true);
    //                                   makeRow(state.question.options);
    //                               }}
    //                           >
    //                               {editOption ? 'Save' : 'Edit'}
    //                           </button>
    //                       </div>
    //                   )
    //               }))
    //             : [];

    //     setOptions(arr);
    // };

    useEffect(() => {
        if (state.question) {
            setOptions(state?.question?.options);
            console.log(state?.question?.options);
        }
    }, [state.question]);

    const getNextQuestion = (data) => {
        // data is option

        if (editOption === data._id) {
            return (
                <Select
                    value={selectedOption || 'null'}
                    label="Next Question"
                    onChange={(e) => {
                        setSelectedOption(e.target.value);
                    }}
                >
                    <MenuItem defaultChecked value="null">
                        Select Next Question
                    </MenuItem>
                    {questionsList?.map((data) => (
                        <MenuItem key={data} value={data}>
                            {data?.question}
                        </MenuItem>
                    ))}
                </Select>
            );
        } else {
            if (!data.nextQuestion) return <div className="red bold">Not Assigned</div>;
            else {
                return data.nextQuestion.question;
            }
        }
    };

    return (
        <div className="Order-Details">
            <div className="align-items-center mb-5">
                <Typography variant="h4" sx={{ color: '#034562', marginBottom: '15px' }}>
                    Question Details
                </Typography>
            </div>
            <div className="mb-4">
                <div className="bold mb-1 font-16">Question</div>
                <div>{state.question.question}</div>
            </div>
            <div className="mb-4">
                <div className="bold mb-1 font-16">Type</div>
                <div>{state.question.questionType}</div>
            </div>
            {/* <div>
                <div className="bold mb-2 font-16">Options</div>
                <table>
                    <thead>
                        <tr>
                            <td
                                style={{
                                    border: '1px solid gray',
                                    width: 150,
                                    padding: 20,
                                    textAlign: 'center',
                                    fontWeight: '600'
                                }}
                            >
                                Option
                            </td>
                            <td
                                style={{
                                    border: '1px solid gray',
                                    width: 150,
                                    padding: 20,
                                    textAlign: 'center',
                                    fontWeight: '600'
                                }}
                            >
                                Next Question
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {state.question.options.map((e) => (
                            <tr>
                                <td style={{ border: '1px solid gray', textAlign: 'center' }}>{e.option}</td>
                                <td style={{ border: '1px solid gray', textAlign: 'center' }}>{e.nextQuestion || 'Null'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> */}
            {options.length > 0 && (
                <div>
                    <div className="bold mb-2 font-16">Options</div>

                    <table className="custom-table wd-80">
                        <thead>
                            <tr>
                                <td className="font-12 wd-20">Option</td>
                                <td className="font-12 wd-10">Code</td>
                                <td className="font-12 wd-40">Next Question</td>
                                <td className="font-12 wd-20">Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {options.map((e) => (
                                <tr>
                                    <td className="bold wd-20">{e.option}</td>
                                    <td className="bold wd-10">{e.code}</td>
                                    <td className="bold wd-40">{getNextQuestion(e)}</td>
                                    <td className="bold wd-20">
                                        <button
                                            className="edit-btn"
                                            onClick={() => {
                                                if (editOption === e._id) {
                                                    if (selectedOption && selectedOption !== 'null') {
                                                        let _options = [...options].map((e) => {
                                                            if (editOption === e._id) {
                                                                return {
                                                                    ...e,
                                                                    nextQuestion: selectedOption
                                                                };
                                                            }
                                                            return e;
                                                        });
                                                        setOptions(_options);
                                                        setEditOption();
                                                        setSelectedOption();
                                                    } else {
                                                        makeToast('error', 'Unable to update!');
                                                        setEditOption();
                                                    }
                                                } else {
                                                    if (!e?.nextQuestion) {
                                                        setEditOption(editOption ? null : e._id);
                                                    } else {
                                                        setEditOption(editOption ? null : e._id);
                                                        // makeToast('error', 'Already!');
                                                    }
                                                }
                                            }}
                                        >
                                            {editOption === e._id ? 'Save' : 'Edit'}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* <DataTable columns={columns} data={options} responsive={true} /> */}
        </div>
    );
}
