import React, { useState } from 'react';
import { Stack, TextField, Typography, Button } from '@mui/material';
import { Modal } from 'react-bootstrap';
import makeToast from 'utils/Toaster';
import { useTranslation } from 'react-i18next';
import { importPdfForPathways } from 'store/redux/actions/questions';

const ImportJsonModal = ({ setShow, state }) => {
  const { t } = useTranslation();
  const [importedData, setImportedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const closeModal = () => setShow(false);

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) throw new Error('Please select a file');
      if (file.type !== 'application/json') throw new Error('Invalid file type\nPlease select a JSON file');

      const reader = new FileReader();
      reader.onload = (e) => {
        const json = JSON.parse(e.target.result);
        setImportedData(json);
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      makeToast(t('error'), error.message);
    }
  };

  const handleImportSubmit = async () => {
    const payload = {
      diseaseID: state.diseaseId,
      version: state.version,
      lang: 'ar',
      importedData
    };
    setLoading(true);
    const res = await importPdfForPathways(payload);
    setLoading(false);
    if (res) closeModal();
  };

  return (
    <Modal show onHide={closeModal} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <Modal.Header closeButton>
        <Modal.Title>Import JSON - {state.version}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <Typography variant="body1" color="inherit" mb={2}>
          Import {state.version} questions for JSON file
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="button">Select File</Typography>
          <TextField
            type="file"
            inputProps={{
              accept: '.json'
            }}
            onChange={handleFileChange}
          />
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleImportSubmit} disabled={loading}>
          Import
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportJsonModal;
