import { AUTH_USER, USER_LOGOUT, AUTH_HOSPITAL } from 'store/redux/actions/action.constants';
import { Log } from 'views/utilities/helper';

const initial_state = {
  isAuthenticated: false,
  user: {}
};

export default function (state = initial_state, action) {
  switch (action.type) {
    case AUTH_USER:
      // Log('loginUser AUTH_USER', action.payload);
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: true,
        user: action.payload,
        isSuperAdmin:
          action?.payload?.user?.userRole === 'opto_supper_admin' || action?.payload?.user?.userRole === 'opto_admin' ? true : false
      };
    case AUTH_HOSPITAL:
      // Log('loginUser AUTH_USER', action.payload);
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: false,
        user: action.payload,
        isSuperAdmin: false
      };
    case USER_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
}
