import { useState, useCallback } from 'react';
import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useTranslation } from 'react-i18next';

const MenuList = () => {
  const { t } = useTranslation();
  const [forceUpdate, setForceUpdate] = useState(false); // add state variable

  const nav = useCallback(() => {
    if (JSON.parse(localStorage.getItem('user'))?.email) {
      const email = JSON.parse(localStorage.getItem('user'));
      return menuItem(email).items.map((item) => {
        switch (item.type) {
          case 'group':
            return (
              <NavGroup
                key={item.id}
                item={item.children}
                forceUpdate={forceUpdate} // pass state variable as prop
              />
            );
          default:
            return (
              <Typography key={item.id} variant="h6" color="error" align="center">
                {/* Menu Items Error */}
              </Typography>
            );
        }
      });
    }
    return <></>;
  }, [forceUpdate]);

  return <>{nav()}</>;
};

export default MenuList;
