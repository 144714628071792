export const AUTH_USER = 'AUTH_USER';
export const AUTH_HOSPITAL = 'AUTH_HOSPITAL';
export const USER_LIST = 'USER_LIST';
export const DOCTORS_LIST = 'DOCTORS_LIST';
export const DOCTOR_DATA = 'DOCTOR_DATA';
export const DOCTOR_APOINTMENTS_LIST = 'DOCTOR_APOINTMENTS_LIST';
export const USER_LOGOUT = 'USER_LOGOUT';
export const CLEAR_DOCTOR_APOINTMENT = 'CLEAR_DOCTOR_APOINTMENT';
export const SELECTED_APOINTMENTS = 'SELECTED_APOINTMENTS';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_APOINTMENT_LIST = 'GET_USER_APOINTMENT_LIST';
export const CLEAR_USER_APOINTMENT_LIST = 'CLEAR_USER_APOINTMENT_LIST';
export const ADMIN_LIST = 'ADMIN_LIST';

// Disease
export const GET_ALL_DISEASE = 'GET_ALL_DISEASE';
export const SELECT_DISEASE = 'SELECT_DISEASE';
export const GET_ALL_QUESTION_DISEASEID = 'GET_ALL_QUESTION_DISEASEID';
export const CLEAR_QUESTION_LIST = 'CLEAR_QUESTION_LIST';

//Clinical
export const GET_USER_CLINICAL_DATA = 'GET_USER_CLINICAL_DATA';
export const CLEAR_USER_CLINICAL_DATA = 'CLEAR_USER_CLINICAL_DATA';

//Hospital
export const HOSPITALS_LIST = 'Hospital_List';
export const CREATE_HOSPITAL = 'CREATE_HOSPITAL';
export const HOSPITAL_BY_ID = 'HOSPITAL_BY_ID';

//Hospital Devices
export const HOSPITAL_DEVICES_LIST = 'HOSPITAL_DEVICES_LIST';
