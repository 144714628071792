import { Constants } from 'constants/Constants';
import moment from 'moment';
import request from 'utils/request';
import { Day_Availability } from 'views/utilities/constant';
import { Log } from 'views/utilities/helper';
import makeToast from 'views/utilities/Toast';
import { useTranslation } from 'react-i18next';
import {
  CLEAR_USER_APOINTMENT_LIST,
  GET_USER_APOINTMENT_LIST,
  GET_USER_DATA,
  SELECTED_APOINTMENTS,
  USER_LIST,
  GET_USER_CLINICAL_DATA,
  CLEAR_USER_CLINICAL_DATA,
  ADMIN_LIST
} from '../action.constants';

export const listOfUser = () => async (dispatch) => {
  Log('listOfUser call');
  console.log('LIST OF USER CALLED');
  try {
    const res = await request.post(Constants.userlist_api_url);
    const { message, status, data } = res.data;

    if (!status) throw res.data;
    if (status) {
      Log('Data', data);
      dispatch({
        type: USER_LIST,
        payload: data.data
      });
    }
    console.log('LIST OF USER END');
  } catch (e) {
    console.log('error ', e.message);
  }
};

export const _listOfUser = async (page) => {
  try {
    const res = await request.post(Constants.userlist_api_url, { page });
    const { message, status, data } = res.data;

    if (!status) throw res.data;
    if (status) {
      return data;
    }
  } catch (e) {
    makeToast('error', e.message);
    throw new Error(e.message);
  }
};

export const _getUsersByHospital = async (id, page) => {
  try {
    const res = await request.post(Constants.getUsersByHospital, { hospital: id, page });
    const { message, status, data } = res.data;

    if (!status) throw res.data;
    if (status) {
      return data;
    }
  } catch (e) {
    makeToast('error', e.message);
    throw new Error(e.message);
  }
};

export const getUsersByHospital = (id) => async (dispatch) => {
  Log('listOfUser call');
  console.log('BY HOSPITAL ID CALLED');
  try {
    const res = await request.post(Constants.getUsersByHospital, { hospital: id });
    const { message, status, data } = res.data;

    if (!status) throw res.data;
    if (status) {
      Log('Data', data);
      dispatch({
        type: USER_LIST,
        payload: data.data
      });
    }
    console.log('BY HOSPITAL ID DONE');
  } catch (e) {
    Log('error ', e);

    makeToast('error', e.message);
  }
};

export const getUserApointmentListingById = (id) => async (dispatch) => {
  Log('getUserApointmentListingById');
  try {
    const res = await request.post(Constants.getUserApointmnetlist_api_url, { id });
    const { message, status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      Log('Data getUserApointmentListingByIdById Response', data?.data);
      dispatch({
        type: GET_USER_APOINTMENT_LIST,
        payload: data
      });
    }
  } catch (e) {
    Log('getUserApointmentListingById error', e);

    // makeToast("getAllUserListing error", e.message);
  }
};

export const getUserClinicalData = (inputData) => async (dispatch) => {
  try {
    const datain = { userId: '628631c442c54d3fa7260feb' };
    const res = await request.post(Constants.getUserClinicalData_api_url, inputData);
    const { message, status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      dispatch({
        type: GET_USER_CLINICAL_DATA,
        payload: data
      });
    }
  } catch (e) {
    Log('getUserClinicalData error', e);
  }
};

export const clearUserApointment = () => async (dispatch) => {
  dispatch({
    type: CLEAR_USER_APOINTMENT_LIST
  });
};

export const clearUserClinicalData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_USER_CLINICAL_DATA
  });
};

export const selectedApointments = (data) => (dispatch) => {
  Log('Selected Actions', data);
  dispatch({
    type: SELECTED_APOINTMENTS,
    payload: data
  });
};

export const getUserData = (data) => (dispatch) => {
  Log('getuserdata Actions', data);
  dispatch({
    type: GET_USER_DATA,
    payload: data
  });
};

export const refreshApointments =
  (date = new Date()) =>
  (dispatch) => {
    Log('refresh Apointmnets');
    dispatch({
      type: SELECTED_APOINTMENTS,
      payload: [
        {
          dateOfAvailability: date,
          status: true,
          slots: [
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '00:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '00:30',
              status: 'unavailable',
              index: '0',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '00:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '01:00',
              status: 'unavailable',
              index: '1',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '01:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '01:30',
              status: 'unavailable',
              index: '2',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '01:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '02:00',
              status: 'unavailable',
              index: '3',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '02:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '02:30',
              status: 'unavailable',
              index: '4',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '02:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '03:00',
              status: 'unavailable',
              index: '5',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '03:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '03:30',
              status: 'unavailable',
              index: '6',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '03:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '04:00',
              status: 'unavailable',
              index: '7',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '04:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '04:30',
              status: 'unavailable',
              index: '8',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '04:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '05:00',
              status: 'unavailable',
              index: '9',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '05:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '05:30',
              status: 'unavailable',
              index: '10',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '05:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '06:00',
              status: 'unavailable',
              index: '11',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '06:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '06:30',
              status: 'unavailable',
              index: '12',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '06:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '07:00',
              status: 'unavailable',
              index: '13',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '07:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '07:30',
              status: 'unavailable',
              index: '14',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '07:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '08:00',
              status: 'unavailable',
              index: '15',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '08:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '08:30',
              status: 'unavailable',
              index: '16',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '08:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '09:00',
              status: 'unavailable',
              index: '17',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '09:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '09:30',
              status: 'unavailable',
              index: '18',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '09:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '10:00',
              status: 'unavailable',
              index: '19',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '10:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '10:30',
              status: 'unavailable',
              index: '20',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '10:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '11:00',
              status: 'unavailable',
              index: '21',
              timezone: 'am'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '11:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '11:30',
              status: 'unavailable',
              index: '22',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '11:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '12:00',
              status: 'unavailable',
              index: '23',
              timezone: 'am'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '12:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '12:30',
              status: 'unavailable',
              index: '24',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '12:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '01:00',
              status: 'unavailable',
              index: '25',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '13:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '01:30',
              status: 'unavailable',
              index: '26',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '13:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '14:00',
              status: 'unavailable',
              index: '27',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '14:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '14:30',
              status: 'unavailable',
              index: '28',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '14:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '15:00',
              status: 'unavailable',
              index: '29',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '15:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '15:30',
              status: 'unavailable',
              index: '30',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '15:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '16:00',
              status: 'unavailable',
              index: '31',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '16:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '16:30',
              status: 'unavailable',
              index: '32',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '16:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '17:00',
              status: 'unavailable',
              index: '33',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '17:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '17:30',
              status: 'unavailable',
              index: '34',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '17:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '18:00',
              status: 'unavailable',
              index: '35',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '18:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '18:30',
              status: 'unavailable',
              index: '36',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '18:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '19:00',
              status: 'unavailable',
              index: '37',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '19:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '19:30',
              status: 'unavailable',
              index: '38',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '19:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '20:00',
              status: 'unavailable',
              index: '39',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '20:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '20:30',
              status: 'unavailable',
              index: '40',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '20:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '21:00',
              status: 'unavailable',
              index: '41',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '21:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '21:30',
              status: 'unavailable',
              index: '42',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '21:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '22:00',
              status: 'unavailable',
              index: '43',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '22:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '22:30',
              status: 'unavailable',
              index: '44',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '22:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '23:00',
              status: 'unavailable',
              index: '45',
              timezone: 'pm'
            },

            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '23:00',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '23:30',
              status: 'unavailable',
              index: '46',
              timezone: 'pm'
            },
            {
              startTime: moment(date).format('YYYY-MM-DD') + 'T' + '23:30',
              endTime: moment(date).format('YYYY-MM-DD') + 'T' + '24:00',
              status: 'unavailable',
              index: '47',
              timezone: 'pm'
            }
          ]
        }
      ]
    });
  };

export const adminSignup = (payload, onClick) => async (dispatch) => {
  const { t } = useTranslation();
  Log('listOfUser call');

  try {
    const res = await request.post(Constants.admin_signup_api_url, payload);
    const { message, status, data } = res.data;

    if (!status) throw res.data;
    if (status) {
      Log('Data', data);
      makeToast(t(success), t('admin_create_msg'));
      onClick();
    }
  } catch (e) {
    Log('error ', e);

    makeToast(t(error), e.message);
  }
};

export const getlistOfOptoAdmin = () => async (dispatch) => {
  Log('listOfOptoAdmin call');
  try {
    const res = await request.get(Constants.listOfOptoAdmin_api_url);
    const { status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      Log('Data', data);
      dispatch({
        type: ADMIN_LIST,
        payload: data?.adminList
      });
    }
  } catch (e) {
    Log('error ', e);

    makeToast('error', e.message);
  }
};

export const getListOfEdAdmin = (hospitaById) => async (dispatch) => {
  Log('geytListOfEdAdmin call');
  try {
    const res = await request.post(Constants.listOfEdAdmin_api_url, { hospital: hospitaById });
    const { status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      Log('Data', data);
      dispatch({
        type: ADMIN_LIST,
        payload: data?.adminList
      });
    }
  } catch (e) {
    Log('error ', e);

    makeToast('error', e.message);
  }
};

export const updateUserProfile = (payload) => async (dispatch) => {
  Log('listOfOptoAdmin call');
  try {
    const res = await request.post(Constants.updateUser_api_url, payload);
    const { status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      Log('Data', data);
      makeToast('success', 'Admin Updated Successfully!');
      dispatch(getlistOfOptoAdmin());
    }
  } catch (e) {
    Log('error ', e);

    makeToast('error', e.message);
  }
};

export const getUserByUPIC = async (upic) => {
  Log('getUserByUPIC call');
  try {
    const res = await request.post(Constants.getUserByUPIC_api_url, { upic });
    const { status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      return data;
    }
  } catch (e) {
    Log('error ', e);

    makeToast('error', e.message);
  }
};
