import { NodeToolbar, useStore } from 'reactflow';
import { deleteQuestion, attachDetachQuestionByQuestion, detachQuestion } from 'store/redux/actions/questions';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@mui/material';
import { useLocation } from 'react-router';
import { getQuestionByDisease } from 'store/redux/actions/questions';
import { FormControl, Input, InputAdornment } from '@mui/material';
import makeToast from 'views/utilities/Toast';
import { displayMultiLangLabelIfPresent } from 'utils/multiLangUtils';
import AreYouSureModal from 'ui-component/AreYouSureModal';

const selectedNodesSelector = (state) =>
  Array.from(state.nodeInternals.values())
    .filter((node) => node.selected)
    .map((node) => node.id);

export default function QuestionToolbar(props) {
  const [show, setShow] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [detachModal, setDetachModal] = useState(false);
  const [detachableQuestions, setDetachableQuestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { state } = useLocation();

  const selectedNodeIds = useStore(selectedNodesSelector);
  const isVisible = selectedNodeIds.length > 1;
  const question = props?.selectedNode?.data.props;

  const langInUse = localStorage.getItem('i18nextLng');
  const userRec = JSON.parse(localStorage.getItem('user'));

  const deleteQuestionById = async () => {
    const response = await deleteQuestion({
      questionId: props.selectedNode.id,
      preQuestionType: question.questionType,
      preQuestion: question.preQuestion
    });
    if (response && response?.status) {
      props.populatePath();
      props.deleteQuestionProps(props.selectedNode.id);
    }
  };

  const handleAttachEvent = async () => {
    console.log({ selectedQuestion, question });

    let _placement = [...question.placement];
    _placement.push({
      preQuestion: selectedQuestion._id,
      options_ids: [],
      isPreQuestionHaveOptions: selectedQuestion?.questionType !== 'input' ? true : false
    });

    let preQuestion = _placement?.map((e) => {
      return {
        question: e.preQuestion,
        answer: e.options_ids
      };
    });
    let data = {
      currentQuestionId: question._id,
      // isAttach: true,
      otherQuestion: selectedQuestion._id,
      placement: _placement,
      preQuestion
    };
    console.log({ data });

    const response = await attachDetachQuestionByQuestion(data);
    if (response && response?.status) {
      props.populatePath();
      setShow(false);
      setSelectedQuestion({});
    }
  };

  const handleAttach = () => {
    setShow(!show);
    populateQuestions();
  };

  const populateQuestions = async () => {
    const response = await getQuestionByDisease({
      diseaseID: state?.diseaseId,
      version: state?.version
    });
    if (response?.status) {
      setQuestions(
        response?.data
          ?.filter((e) => e.isDeleted === false)
          ?.filter((e) => e.questionType === 'input')
          ?.filter((e) => !e.nextQuestion)
      );
    } else {
      makeToast('error', 'Unable to fetch questions.');
    }
  };

  useEffect(() => {
    if (search === '') setFilteredData([]);
    else {
      filtering();
    }
  }, [search]);

  const filtering = () => {
    let _answers = questions
      ?.filter((e) => e._id !== question._id)
      .filter((e) =>
        displayMultiLangLabelIfPresent(e.question, langInUse, e.multiLanguageQuestion)?.toLowerCase()?.includes(search.toLowerCase())
      );
    setFilteredData(_answers);
    if (_answers.length === 0) {
      makeToast('error', 'No results found', 'error');
    }
  };

  const handleDetach = async () => {
    const response = await getQuestionByDisease({
      diseaseID: state?.diseaseId,
      version: state?.version
    });
    if (response?.status) {
      setDetachableQuestions(response?.data);
      setDetachModal(true);
    } else {
      makeToast('error', 'Unable to fetch questions.');
    }
  };

  const detachQuestionEvent = async () => {
    if (selectedQuestion?._id || selectedOption) {
      const response = await detachQuestion({
        questionId: question._id,
        otherOptionId: selectedOption,
        otherQuestionId: selectedQuestion._id
      });
      console.log({ response });
      if (response?.status) {
        props.setEdges([]);
        props.populatePath();
        setSelectedOption();
        setSelectedQuestion();
        setDetachableQuestions([]);
        setDetachModal(false);
      }
      console.log({ questionId: question, selectedOption, selectedQuestion });
    } else {
      makeToast('error', 'Please select a question to detach.');
    }
  };

  const getContent = (id) => {
    console.log({ id });
    return detachableQuestions.find((e) => e._id === id);
  };

  return (
    <>
      <NodeToolbar nodeId={selectedNodeIds} isVisible={true}>
        <div className="d-flex flex-row">
          {question.questionType === 'input' && !question.nextQuestion && (
            <div
              className="toolbar-add"
              onClick={() => {
                props.createQuestion(props.selectedNode, 'quest');
              }}
            >
              Add Question
            </div>
          )}
          {question.questionType !== 'input' && (
            <div
              className="toolbar-add"
              onClick={() => {
                props.createOption(props.selectedNode);
              }}
            >
              Add Option
            </div>
          )}
          <div className="toolbar-edit" onClick={() => props.editQuestion()}>
            Edit
          </div>
          {!question.isParentQuestion && userRec.userRole != 'author' && (
            // <div className="toolbar-del" onClick={() => deleteQuestionById()}>
            //   Delete
            // </div>
            <div className="toolbar-del" onClick={() => setIsDeleteModalVisible(true)}>
              Delete
            </div>
          )}
          {!question.isParentQuestion && (
            <div className="toolbar-attach" onClick={() => handleAttach()}>
              Attach
            </div>
          )}
          {!question.isParentQuestion && question.placement.length > 0 && (
            <div className="toolbar-attach" onClick={() => handleDetach()}>
              Detach
            </div>
          )}
        </div>
      </NodeToolbar>

      <Modal
        className="question-modal"
        style={{ height: '90vh', top: 20 }}
        scrollable
        show={show}
        centered
        size="lg"
        onHide={() => {
          setShow(false);
          setSelectedQuestion({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Questions List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <FormControl className="answer-multiple">
              <Input
                disableUnderline
                onChange={(e) => setSearch(e.target.value)}
                style={{ fontSize: 12, marginTop: 3 }}
                sx={{ input: { color: 'black' } }}
                value={search}
                placeholder={'Search'}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={require('../../../assets/images/searchIcon.png')} className="logo-smallest" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          {(!filteredData.length ? questions.filter((q) => q._id !== question?._id) : filteredData)?.map((q, e) => (
            <ul key={e} className={selectedQuestion._id === q._id ? 'active-question' : 'questions-list'}>
              <li onClick={() => setSelectedQuestion(q)}>
                {displayMultiLangLabelIfPresent(q.question, langInUse, q.multiLanguageQuestion)}
              </li>
            </ul>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outlined" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="contained" onClick={() => handleAttachEvent()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DETACH MODAL */}
      <Modal
        className="question-modal"
        style={{ height: '90vh', top: 20 }}
        scrollable
        show={detachModal}
        centered
        size="lg"
        onHide={() => {
          setDetachModal(false);
          setSelectedQuestion();
          setSelectedOption();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Questions List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="">
            <FormControl className="answer-multiple">
              <Input
                disableUnderline
                onChange={(e) => setSearch(e.target.value)}
                style={{ fontSize: 12, marginTop: 3 }}
                sx={{ input: { color: 'black' } }}
                value={search}
                placeholder={'Search'}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={require('../../../assets/images/searchIcon.png')} className="logo-smallest" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div> */}
          {question?.placement?.map((preQ, e) => (
            <ul key={e} className="p-0">
              {preQ?.preQuestion?.questionType === 'input' ? (
                <li
                  className={selectedQuestion?._id === preQ?.preQuestion?._id ? 'active-question' : 'questions-list'}
                  onClick={() => {
                    setSelectedQuestion(preQ?.preQuestion);
                  }}
                >
                  {displayMultiLangLabelIfPresent(preQ?.preQuestion?.question, langInUse, preQ?.preQuestion?.multiLanguageQuestion)}
                </li>
              ) : (
                preQ?.preQuestion?.options
                  ?.filter((f) => f?.nextQuestion === question?._id)
                  ?.map((e) => (
                    <li
                      className={selectedOption === e?._id ? 'active-question mb-3' : 'questions-list mb-3'}
                      onClick={() => {
                        setSelectedQuestion(preQ?.preQuestion);
                        setSelectedOption(e._id);
                      }}
                    >
                      {displayMultiLangLabelIfPresent(preQ?.preQuestion?.question, langInUse, preQ?.preQuestion?.multiLanguageQuestion) +
                        ' (' +
                        displayMultiLangLabelIfPresent(e.option, langInUse, e.multiLanguageOption) +
                        ')'}
                    </li>
                  ))
              )}
            </ul>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outlined" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="contained" onClick={() => detachQuestionEvent()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <AreYouSureModal
        isInputType={false}
        show={isDeleteModalVisible}
        onhide={() => {
          setIsDeleteModalVisible(false);
        }}
        title={'Delete Question'}
        description={'Are you sure, you want to delete this question'}
        onDelete={() => {
          console.log('Delete Called');
          deleteQuestionById();
          setIsDeleteModalVisible(false);
        }}
      />
    </>
  );
}
