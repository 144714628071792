import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import { checkAuth } from 'store/redux/actions/auth';
import '@natscale/react-calendar/dist/main.css';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const selected = localStorage.getItem('i18nextLng') || 'en';

    const languageMap = {
        en: { label: "English", dir: "ltr", active: true },
        ar: { label: "العربية", dir: "rtl", active: false }
    };
    useEffect(() => {
        document.body.dir = languageMap[selected]?.dir;
    }, [selected])

    useEffect(() => {
        dispatch(checkAuth());
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
